import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Trendy",
  sku: "TCK12",
  availability: "In stock",
  title: "OCSO Solid Men's T-shirt TCK12",
  mrpPrice: "699",
  sellPrice: "299",
  disc:
    "OCSO basic Men's solid T-shirt,With half sleeves with four charming colors: Blue, Graphide, Black, and Purple, Get ready for your gym session and flaunt a new style wearing this T-shirt from ocso. Made from a blend of 95% polyester and 5% spandex.",
};
export default () => {
  return <ProdLayout data={data} />;
};
